
import { clientToElement } from '@novnc/novnc/core/util/element.js';

const GESTURE_ZOOMSENS = 75;
const GESTURE_SCRLSENS = 50;


export const mixinDetectingMobile = {
    isMobile: function () {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }
}

const isMobileEnv = mixinDetectingMobile.isMobile();

let _lastRFBDisplayScale = 0;

export function _LabGestureHandler(ev) {

    let magnitude;

    let pos = clientToElement(ev.detail.clientX, ev.detail.clientY, this._canvas);

    //console.log("HandleGesture:", ev.type, ev.detail.type, pos.x, pos.y);

    switch (ev.type) {
        case 'gesturestart':
            switch (ev.detail.type) {
                case 'onetap':
                    this._handleTapEvent(ev, 0x1);
                    break;
                case 'twotap':
                    this._handleTapEvent(ev, 0x4);
                    break;
                case 'threetap':
                    this._handleTapEvent(ev, 0x2);
                    break;
                case 'drag':
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    this._handleMouseButton(pos.x, pos.y, true, 0x1);
                    break;
                case 'longpress':
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    this._handleMouseButton(pos.x, pos.y, true, 0x4);
                    break;

                case 'twodrag':
                    this._gestureLastMagnitudeX = ev.detail.magnitudeX;
                    this._gestureLastMagnitudeY = ev.detail.magnitudeY;
                    this._fakeMouseMove(ev, pos.x, pos.y);

                    //this._display._target.style.position = "absolute";
                    this._display._target._lastPos = { x: ev.detail.magnitudeX, y: ev.detail.magnitudeY };

                    //_lastRFBDisplayScale = this._display.scale;

                    //this.clipViewport = true;
                    //this.scaleViewport = false;
                    //this.dragViewport = true;

                    //this._display.scale = _lastRFBDisplayScale;

                    //this._handleMouseButton(posCli.x, posCli.y, true, 1 << ev.button);

                    break;
                case 'pinch':
                    this._gestureLastMagnitudeX = Math.hypot(ev.detail.magnitudeX,
                        ev.detail.magnitudeY);
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    _lastRFBDisplayScale = this._display.scale;
                    break;
            }
            break;

        case 'gesturemove':
            switch (ev.detail.type) {
                case 'onetap':
                case 'twotap':
                case 'threetap':
                    break;
                case 'drag':
                case 'longpress':
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    break;
                case 'twodrag':
                    // Always scroll in the same position.
                    // We don't know if the mouse was moved so we need to move it
                    // every update.
                    this._fakeMouseMove(ev, pos.x, pos.y);

                    if (this._display._target.style.position != "absolute")
                        return;

                    let rectScreen = this._screen.getBoundingClientRect();
                    let rectTarget = this._display._target.getBoundingClientRect();

                    let curOffsetX = ev.detail.magnitudeX - this._display._target._lastPos.x;
                    let curOffsetY = ev.detail.magnitudeY - this._display._target._lastPos.y;

                    let curX = parseInt(this._display._target.style.left) || 0;
                    let curY = parseInt(this._display._target.style.top) || 0;
                                       
                    curX += curOffsetX;
                    curY += curOffsetY;

                    if ((rectTarget.width > rectScreen.width) || (rectTarget.height > rectScreen.height)) {

                        if (curX > 0)
                            curX = 0;

                        if (curY > 0)
                            curY = 0;

                        if (curX < -(rectTarget.width - rectScreen.width))
                            curX = -(rectTarget.width - rectScreen.width);

                        if (curY < -(rectTarget.height - rectScreen.height))
                            curY = -(rectTarget.height - rectScreen.height);
                    }


                    this._display._target.style.left = `${curX}px`;
                    this._display._target.style.top = `${curY}px`;

                    this._display._target._lastPos.x = ev.detail.magnitudeX;
                    this._display._target._lastPos.y = ev.detail.magnitudeY;            

                    //this._handleMouseMove(posCli.x, posCli.y);

                    break;
                case 'pinch':
                    // Always scroll in the same position.
                    // We don't know if the mouse was moved so we need to move it
                    // every update.
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    magnitude = Math.hypot(ev.detail.magnitudeX, ev.detail.magnitudeY);
                    var newscale = this._display.scale;


                    if (Math.abs(magnitude - this._gestureLastMagnitudeX) > GESTURE_ZOOMSENS) {

                        while ((magnitude - this._gestureLastMagnitudeX) > GESTURE_ZOOMSENS) {

                            this._gestureLastMagnitudeX += GESTURE_ZOOMSENS;

                            newscale += 0.256;
                            processPinch(this, newscale);
                        }

                        while ((magnitude - this._gestureLastMagnitudeX) < -GESTURE_ZOOMSENS) {
                            this._gestureLastMagnitudeX -= GESTURE_ZOOMSENS;

                            newscale -= 0.256;
                            processPinch(this, newscale);
                        }

                        _lastRFBDisplayScale = this._display.scale;
                    }

                    break;
            }
            break;

        case 'gestureend':
            switch (ev.detail.type) {
                case 'onetap':
                case 'twotap':
                case 'threetap':
                case 'pinch':
                    break;
                case 'twodrag':

                    //this._display._target.style.position = "absolute";

                    //this.clipViewport = false;
                    //this.scaleViewport = true;

                    //this._display.scale = _lastRFBDisplayScale;
                    //this.dragViewport = false;

                    //this._handleMouseButton(pos.x, pos.y, false, 1 << ev.button);

                    break;
                case 'drag':
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    this._handleMouseButton(pos.x, pos.y, false, 0x1);
                    break;
                case 'longpress':
                    this._fakeMouseMove(ev, pos.x, pos.y);
                    this._handleMouseButton(pos.x, pos.y, false, 0x4);
                    break;
            }
            break;
    }
}


function processPinch(argRFBObj, argPinchValue) {

    let finalScale = argPinchValue;

    finalScale = Math.min(finalScale , 1.5);
    finalScale = Math.max(finalScale, argRFBObj._display._target._MinimumScale);

    argRFBObj._display.scale = finalScale;

    let rectScreen = argRFBObj._screen.getBoundingClientRect();
    let rectTarget = argRFBObj._display._target.getBoundingClientRect();

    if ((rectTarget.width > rectScreen.width) || (rectTarget.height > rectScreen.height) ) {

        argRFBObj._display._target.style.position = 'absolute';
    } else {
        argRFBObj._display._target.style.position = 'relative';
        argRFBObj._display._target.style.top = '0px';
        argRFBObj._display._target.style.left = '0px';
    }

    if (argRFBObj._display._target.style.position == 'absolute') {

        argRFBObj._display._target.style.left = `-${(rectTarget.width - rectScreen.width) / 2}px`;
        argRFBObj._display._target.style.top = `-${(rectTarget.height - rectScreen.height) / 2}px`;
    }

}