<template>
    <div class="login-page">

        <div class="heading-page">
            <img class="labware_logo" src="@/assets/logo.png" />
        </div>
        
        <div class="login-body">

            <form class="login-box" onsubmit="return false;">

                <div class="grid-row">
                    <label for="ask-device-id">Device Id</label>

                    <input id="ask-device-id" class="input-uppercase" v-model="askDevice" />

                </div>

                <div class="grid-row">
                    <label for="ask-password">Password</label>

                    <input id="ask-password" v-model="askPassword" type="password" />

                </div>
                <div class="grid-row">
                    <!-- VOID ROW -->
                </div>

                <div class="grid-row button-action">
                    <button @click="do_connection" default>
                        <div class="button-content">
                            <p>Connect</p>
                            <div v-if="connecting" class="wait-loader">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </div>
                    </button>
                </div>

                <div v-if="nodeEnv == 'development'" class="grid-row grid-plain">
                    <label for="ask-direct-connect">Direct connection</label>
                    <input id="ask-direct-connect" type="checkbox" v-model="askDirectConnect"/>
                </div>

            </form>
        
        </div>

    </div>


</template>

<script>

    import { mapGetters, mapMutations, mapActions } from 'vuex';


    export default {
        name: 'loginPage',

        data() {
            return {
                nodeEnv: process.env.NODE_ENV,
                connecting: false,
            }
        },

        computed: {

            ...mapGetters([
                "lastError",
            ]),

            askDevice: {

                get() {
                    return this.$store.state._askDeviceId;
                },
                set(value) {
                    this.$store.commit("setAskDeviceId", value.toUpperCase());
                }
            },

            askPassword: {
                get() {
                    return this.$store.state._askPassword;
                },
                set(value) {
                    this.$store.commit("setAskPassword", value);
                }
            },

            askDirectConnect: {
                get() {
                    return this.$store.state._askDirectConnect;
                },
                set(value) {
                    this.$store.commit("setAskDirectConnect", value);
                }
            }

        },

        watch: {

            askDevice(value) {
                if (value != "") {
                    this.resetValidity("ask-device-id");
                }
            },

            askPassword(value) {
                if (value != "")
                    this.resetValidity("ask-password");
            },

            lastError(value) {
                if (value != "")
                    this.connecting = false;
            }

        },

        methods: {

            ...mapActions([
                "doHandShake",
                "doDirectConnection",
            ]),

            resetValidity(argObjId) {

                let obj = document.getElementById(argObjId);

                if (obj != null) {
                    obj.setCustomValidity("");
                }
            },

            async do_connection() {

                let in_error = [];

                this.resetValidity("ask-device-id");
                this.resetValidity("ask-password");

                if (this.askDevice == "") {
                    in_error.push({ id: "ask-device-id", msg: "Mandatory Device Id"});
                }

                if (this.askPassword == "") {
                    in_error.push({ id: "ask-password", msg: "Mandatory Password" });
                }

                if (in_error.length > 0) {

                    for (let inObj of in_error) {
                        let errObj = document.getElementById(inObj.id);
                        if (!errObj)
                            continue;

                        errObj.setCustomValidity(inObj.msg);
                        errObj.reportValidity();
                    }

                    return;
                }

                this.$store.commit("setError", "");

                try {

                    this.connecting = true;

                    await this.doHandShake();

                } catch(e) {

                    this.connecting = false;
                }

            },
        },

        mounted() {
            this.connecting = false;

            this.$nextTick(() => {

                document.getElementById("ask-device-id").focus();

    /*
                if (this.nodeEnv == "development") {

                    this.askDirectConnect = true;
                    this.askDevice = "192.168.1.133";
                    this.askPassword = "labwareb";
                    this.do_connection();
                }
    */

            });
        }

    }



</script>

<style lang="scss" scoped>

    .login-page {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    .heading-page {
        width: 100%;
        flex: 0 0 5.5rem;
        overflow: hidden;
        display: flex;
        flex-flow: row nowrap;
        background-color: #bf0000;
        padding: .5rem 1.2rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        
        & > .labware_logo {
            height: 4rem;
            margin-right: 5rem;
        }

        & > .app_title {
            color: white;
            font-size: calc(var(--base-font-size) + 1.2rem);
            margin-block-start: 0;
            margin-block-end: 0;
        }

    }

    .login-body {
        flex: 1 1 auto;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    .login-box {
        width: 30rem;
        border: 2px solid #bf0000;
        border-radius: 8px;
        padding: 1rem;
        display: flex;
        flex-flow: column nowrap;
    }

    .button-action {
        justify-content: center;
        
        & > button {
            height: 100%;
            flex: 0 0 80%;
            font-size: calc(var(--base-font-size) + 0.5rem);
            cursor: pointer;
        }
    }

    .button-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        position: relative;

        & > .wait-loader {
            position: absolute;
            right: 2rem;
            top: 0;
        }
    }

    .grid-row {
        flex: 0 0 3.5rem;
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        align-items: center;
        & > label {
            flex: 0 0 33%;
            font-size: var(--base-font-size);
        }

        & > input {
            flex: 1 1 auto;
            padding: .3rem .4rem;
            font-size: var(--base-font-size);
        }
    }

    .grid-plain {
        
        & > label {
            flex: 0 0 auto;
            padding-right: .5rem;
        }

        & > input[type='checkbox'] {
            flex: 0 0 auto;
        }
    }

    .input-uppercase {
        text-transform: uppercase;
    }

</style>