<template>
    <div id="app">

        <login-page v-if="!Connected" />
        
        <vnc-page v-else />

    </div>
</template>

<script>

    import loginPage from '@/components/loginPage.vue';
    import vncPage from '@/components/vncPage.vue';

    import { mapGetters, mapMutations, mapActions } from 'vuex';


    export default {
        name: 'app',
        
        components: {
            loginPage,
            vncPage
        },

        data() {
            return {

            }
        },

        computed:
        {
            ...mapGetters([
                "Connected",
            ]),
        },

        mounted() {

        }

    };
</script>

<style lang="scss">
    @import "~bulma/sass/utilities/_all";
    /* CUSTOMIZATION */

    $primary: #bf0000;

    $danger: #bf0000;

    @import "~bulma";
    @import "~buefy/src/scss/buefy";

    @import "styles/global.scss";
</style>

<style lang="scss" scoped>

    #app {
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #fefefe;
        user-select: none;
        font-size: var(--base-font-size);
    }

</style>
