<template>
    <div class="vnc-page">

        <div class="heading-page-connected">

            <button id="vnc-setting-button" class="vnc-button" :class="{ 'selected': settingsOpened}" @click="do_openSettings" title="Settings">
                <img src="@/assets/icon_menu.png" />
            </button>

            <div class="vnc-device-id"><span>Connected to:</span> <p>{{rfbOptions.device_id}}</p></div>

            <button v-show="!isMobile" id="vnc-scaleWindowView-button" class="vnc-button" :class="{ 'selected': scaleWindowView}" @click="do_scaleWindowView" title="Scale Window Viewport">
                <img src="@/assets/fullscreen.svg" />
            </button>

            <button id="vnc-disconnect-button" class="vnc-button" @click="do_disconnection(true)" title="Disconnect">
                <img src="@/assets/disconnect.svg" />
            </button>

        </div>

        <div v-if="settingsOpened" class="settings-area">

            <div class="settings-panel">

                <div class="setting-row">

                    <div class="column-label">
                        <label>Quality</label>
                    </div>

                    <div class="column-value">
                        <b-slider class="m-0 p-3" v-model="qualityLevel" lazy :min="1" :max="9" tiks type="is-white" rounded size="is-medium" />
                    </div>

                </div>

                <div class="setting-row">

                    <div class="column-label">
                        <label>Compression</label>
                    </div>

                    <div class="column-value">
                        <b-slider class="m-0 p-3" v-model="compressionLevel" lazy :min="1" :max="9" tiks type="is-white" rounded size="is-medium" />
                    </div>

                </div>
            
            </div>

            <div class="void-area" @click="do_openSettings">

            </div>

        </div>

        <div id="vnc-framebox" class="vnc-frame"></div>

    </div>
</template>

<script>
    import RFB from '@novnc/novnc/core/rfb';

    import { mapGetters, mapMutations, mapActions } from 'vuex';

    import { _LabGestureHandler } from '@/utils'

    const DEFAULT_QUALITY_LEVEL = 4;
    const DEFAULT_COMPRESSION_LEVEL = 8;


    export default {
        name: 'vncPage',

        data() {

            return {

                rfb: null,
                vncConnected: false,
                desktopName: "",
                scaleWindowView: false,
                settingsOpened: false,
                qualityLevel: DEFAULT_QUALITY_LEVEL,
                compressionLevel: DEFAULT_COMPRESSION_LEVEL,
            }
        },

        computed: {

            ...mapGetters([
                "isMobile",
                "Connected",
                "rfbOptions",
                "bridgeUrl"
            ]),

        },

        watch:
        {
            qualityLevel(value) {
                if (this.rfb)
                    this.rfb.qualityLevel = this.qualityLevel;

                window.localStorage.setItem("qualityLevel", this.qualityLevel );
            },

            compressionLevel(value) {
                if (this.rfb)
                    this.rfb.compressionLevel = this.compressionLevel;

                window.localStorage.setItem("compressionLevel", this.compressionLevel);
            },
        },

        methods: {

            create_rfb_object() {

                let elem_container = window.document.getElementById("vnc-framebox");

                if (elem_container == null) {
                    window.setTimeout(this.create_rfb_object, 300);
                    return;
                }

                try {

                    this.rfb = new RFB(elem_container, this.bridgeUrl, this.rfbOptions);

                    if (this.rfb == null) {
                        console.log("errore websocket");
                        return;
                    }

                    this.rfb.viewOnly = false;
                    this.rfb.addEventListener("connect", this.connectFinished);
                    this.rfb.addEventListener("disconnect", this.disconnectFinished);
                    this.rfb.addEventListener("credentialsrequired", this.credentials);
                    this.rfb.addEventListener("securityfailure", this.securityFailed);
                    this.rfb.addEventListener("capabilities", this.updatePowerButton);
                    this.rfb.addEventListener("clipboard", this.clipboardReceive);
                    this.rfb.addEventListener("bell", this.bell);
                    this.rfb.addEventListener("desktopname", this.updateDesktopName);
                    this.rfb.qualityLevel = this.qualityLevel;
                    this.rfb.compressionLevel = this.compressionLevel;
                    this.rfb.scaleViewport = this.scaleWindowView;

                    this.rfb._screen.style.position = "relative";
                    this.rfb._canvas.style.margin = '0 auto';

                    if (this.isMobile) {

                        this.rfb._screen.style.overflow = 'hidden';
                        this.rfb._screen.addEventListener("touchstart", function(ev) { ev.stopPropagation(); ev.preventDefault(); return false; });

                        this.rfb._canvas.removeEventListener("gesturestart", this.rfb._eventHandlers.handleGesture);
                        this.rfb._canvas.removeEventListener("gesturemove", this.rfb._eventHandlers.handleGesture);
                        this.rfb._canvas.removeEventListener("gestureend", this.rfb._eventHandlers.handleGesture);

                        this.rfb._eventHandlers.handleGesture = _LabGestureHandler.bind(this.rfb);

                        this.rfb._canvas.addEventListener("gesturestart", this.rfb._eventHandlers.handleGesture);
                        this.rfb._canvas.addEventListener("gesturemove", this.rfb._eventHandlers.handleGesture);
                        this.rfb._canvas.addEventListener("gestureend", this.rfb._eventHandlers.handleGesture);
                    }

                } catch (e) {

                    let buf_err = `Error ${e} occurred during RFB initialization`;

                    console.log(buf_err);

                    this.$store.commit("createRfbOptions", false);
                    this.$store.commit("setConnected", false);
                    this.$store.commit("setError", buf_err);

                    this.vncConnected = false;
                }

            },

            do_disconnection(argInteractive) {

                if (argInteractive) {
                    if (!window.confirm("Confirm disconnection ?"))
                        return;
                }

                if (this.rfb) {

                    if (this.isMobile) {
                        this.rfb._canvas.removeEventListener("gesturestart", this.rfb._eventHandlers.handleGesture);
                        this.rfb._canvas.removeEventListener("gesturemove", this.rfb._eventHandlers.handleGesture);
                        this.rfb._canvas.removeEventListener("gestureend", this.rfb._eventHandlers.handleGesture);
                    }

                    this.rfb.disconnect();
                } else {
                    this.vncConnected = false;
                    this.$store.commit("setConnected", false);
                }
            },

            do_scaleWindowView() {
                this.scaleWindowView = !this.scaleWindowView;

                if (this.rfb) {
                    try {
                        this.rfb.scaleViewport = this.scaleWindowView;
                    } catch (e) {
                        this.scaleWindowView = false;
                    }
                }

                window.localStorage.setItem("scaleWindowView", this.scaleWindowView);
            },

            do_openSettings() {
                this.settingsOpened = !this.settingsOpened;
            },

            connectFinished(rfb_evt) {
                //console.log("connectFinished" ,rfb_evt);

                this.vncConnected = true;

                document.title = this.rfbOptions.device_id + " - Labware remote assistance";

                this.rfb._display._target._MinimumScale = this.rfb._display.scale;
            },

            disconnectFinished(rfb_evt) {
                //console.log("disconnectFinished", rfb_evt);
                
                this.rfb = undefined;
                this.vncConnected = false;

                this.$store.commit("setConnected", false);
                this.$store.commit("createRfbOptions", false);

                document.title = "Labware remote assistance";

                if (rfb_evt.detail) {
                    if (rfb_evt.detail.clean == false) {
                        this.$store.commit("setError", "Unable to contact service");
                    }
                }

            },

            credentials(rfb_evt) {
                //console.log("credentials", rfb_evt);
            },

            updatePowerButton(rfb_evt) {
                //console.log("updatePowerButton", rfb_evt);

            },

            clipboardReceive(rfb_evt) {
                //console.log("clipboardReceive", rfb_evt);
            },

            bell(rfb_evt) {
                //console.log("bell", rfb_evt);

            },

            updateDesktopName(rfb_evt) {
                //console.log("updateDesktopName", rfb_evt);

                this.desktopName = rfb_evt.detail.name;
            },

            securityFailed(rfb_evt) {
                //console.log("securityFailed", rfb_evt);

                this.do_disconnection();

                this.$store.commit("setError", rfb_evt.detail.reason);
            },
        },

        mounted() {
            //console.log("RFB MOUNTED");
            let storageValue = null;

            storageValue = window.localStorage.getItem("qualityLevel");
            if (storageValue != null)
                this.qualityLevel = parseInt(storageValue) || DEFAULT_QUALITY_LEVEL;

            storageValue = window.localStorage.getItem("compressionLevel");
            if (storageValue != null)
                this.compressionLevel = parseInt(storageValue) || DEFAULT_COMPRESSION_LEVEL;

            if (this.isMobile) {

                this.scaleWindowView = true;

            } else {

                storageValue = window.localStorage.getItem("scaleWindowView");
                if (storageValue != null)
                    this.scaleWindowView = (storageValue == "true");
            }

            this.$nextTick(() => {
                this.create_rfb_object();
            });
            
        },

        beforeUnmount() {
            //console.log("RFB BEFORE UNMOUNT");

            do_disconnection(false);
        }
    };

</script>

<style lang="scss" scoped>

    .vnc-page {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        background: #555;
    }
    
    .vnc-frame {
        flex: 1 1 100%;
        overflow: hidden;
    }

    .heading-page-connected {
        width: 100%;
        flex: 0 0 3rem;
        overflow: hidden;
        display: flex;
        flex-flow: row nowrap;
        background-color: #bf0000;
        padding: .2rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        & > .labware_logo  {
            height: 2rem;
            margin-right: 5rem;
            flex: 0 0 auto;
        }
    }

    .vnc-device-id {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: baseline;
        padding: 0 .5rem;
        color: white;
        & > span {
            font-size: calc(var(--base-font-size) - 0.25rem);
        }

        & > p {
            padding-left: 0.8rem;
        }
    }

    .button-area {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 auto;
        justify-content: flex-end;
        
    }

    .vnc-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.4rem;
        width: 2.4rem;
        border: 0px solid rgba(255, 255, 255, 1);
        border-radius: 6px;
        background: #bf0000;
        cursor: pointer;
        margin-left: 0.3rem;
        &:hover, &.selected {
                    background: #f00000;
                }

        & > img {
            flex: 1 1 auto;
        }
    }

    .settings-area {
        position: absolute;
        top: 3rem;
        left: 0;
        width: 100%;
        height: calc(100% - 3rem);
        display: flex;
        flex-flow: row nowrap;
        z-index: 3000;

        & > .void-area {
            flex: 1 1 auto;
            opacity: 0.5;
            background-color: black;
        }
    }

    .settings-panel {
        flex: 0 0 20rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #bf0000;
        border-top: .1rem solid #930b09;
        padding: .5rem .5rem;
    }

    .setting-row {
        padding-right: 0.5rem;
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        
        & .column-label {

            font-size: calc(var(--base-font-size) - 0.2rem);
            flex: 1 1 auto;
            color: white;
        }

        & .column-value {
            flex: 1 1 auto;
        }
    }

</style>
