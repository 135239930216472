import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';

import store from './store';

Vue.use(Buefy);

Vue.config.productionTip = true;

new Vue({

    store,

    render: h => h(App)

}).$mount('#app');
