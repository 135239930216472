import Vue from 'vue';
import Vuex from 'vuex';

import { ToastProgrammatic as Toast } from 'buefy';
import { mixinDetectingMobile } from './utils';

Vue.use(Vuex);

const PRIVATE_BRIDGE_ADDRESS = "bridgebroker.labware.local";
const PRIVATE_BRIDGE_PORT = "58001";

const PUBLIC_BRIDGE_ADDRESS = "remotepos-broker.4cloudoffice.com";
const PUBLIC_BRIDGE_PORT = "58001";

const store = new Vuex.Store({

    state: {
        _isMobile: mixinDetectingMobile.isMobile(),
        _connected: false,
        _askDeviceId: "",
        _askPassword: "",
        _askDirectConnect: false,
        _bridgeAddress: "",
        _bridgePort: "",
        _bridgeSession: "",
        _bridgeOtp: "",
        _requestUrl: "",
        _lastError: "",
        _rfbOptions: {},
    },

    getters: {

        Connected(state) {
            return state._connected;
        },

        isMobile(state) {
            return state._isMobile;
        },

        askDeviceId(state) {
            return state._askDeviceId;
        },

        askPassword(state) {
            return state._askPassword;
        },

        askDirectConnect(state) {
            return state._askDirectConnect;
        },

        rfbOptions(state) {
            return state._rfbOptions;
        },

        bridgeUrl(state) {
            return state._requestUrl;
        },

        lastError(state) {
            return state._lastError;
        }

    },

    mutations: {

        setAskDeviceId: (state, value) => { state._askDeviceId = value; },

        setAskPassword: (state, value) => { state._askPassword = value; },

        setAskDirectConnect: (state, value) => { state._askDirectConnect = value; },

        setConnected: (state, value) => { state._connected = value; },

        setBridgeAddress: (state, value) => {

            let public_url = value.split(":");

            state._bridgeAddress = public_url[0];
            state._bridgePort = public_url[1];
        },

        setBridgeSession: (state, value) => { state._bridgeSession = value; },

        setBridgeOtp: (state, value) => { state._bridgeOtp = value; },

        setRequestUrl: (state, value) => {
            if (value != "") {
                state._requestUrl = value + "://" + state._bridgeAddress + ":" + state._bridgePort + "/labware_remote_bridge_agent";
            } else {
                state._requestUrl = "";
            }
        },

        createRfbOptions: (state, value) => {

            if (value == false) {
                Object.assign(state._rfbOptions, {
                    is_valid: false,
                });

            } else {
                Object.assign(state._rfbOptions, {
                    is_valid: true,
                    shared: true,
                    credentials: {
                        password: state._askPassword
                    },
                    device_id: state._askDeviceId,
                    wsProtocols: [
                        "binary",
                        `auth.1.${state._bridgeSession}`,
                        `auth.2.${state._askDeviceId}`,
                        `auth.3.${state._bridgeOtp}`
                    ]
                });
            }
        },

        setError: (state, errorMsg) => {

            state._lastError = errorMsg;

            if (errorMsg != "") {

                Toast.open({
                    duration: 5000,
                    message: `Error: ${errorMsg}`,
                    position: 'is-bottom',
                    type: 'is-danger'
                });
            }
        }
    },

    actions: {

        async doHandShake(context) {

            let error_msg = "";

            let broker_host = PUBLIC_BRIDGE_ADDRESS;  
            let broker_port = PUBLIC_BRIDGE_PORT;

            if (process.env.NODE_ENV == "development") {
                broker_host = PRIVATE_BRIDGE_ADDRESS;
                broker_port = PRIVATE_BRIDGE_PORT;
            }

            let auth_token = btoa(Date.now.toString());

            let bodyData = {
                'client_type': 'HTTP-AGENT',
                'request_id': 'ASK_FOR_SERVICE',
                'device_uid': context.state._askDeviceId,
                'service_name': 'VNC',
                'password': auth_token
            }

            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Agent': 'noVNC',
                    'Upgrade-Insecure-Requests': 1
                },
                body: JSON.stringify(bodyData)
            }

            let broker_destination = `https://${broker_host}:${broker_port}`;
            let broker_request_url = `${broker_destination}/api-services/askfor_device_service`;

            let response = null;

            if (context.state._askDirectConnect) {

                context.commit("setBridgeAddress", `${context.state._askDeviceId}:5900`);
                context.commit("setBridgeSession", "");
                context.commit("setBridgeOtp", "");

                context.commit("setRequestUrl", "ws");
                context.commit("createRfbOptions", true);

                context.commit("setConnected", true);

            } else {

                try {

                    response = await fetch(broker_request_url, options);

                } catch (e) {

                    response = null;
                    error_msg = `Unable to contact bridge service at ${broker_destination}`; 
                }

                if (response && response.ok) {

                    let json_data = await response.json();

                    if (json_data.status == "OK") {

                        context.commit("setBridgeAddress", json_data.public_port);
                        context.commit("setBridgeSession", json_data.session_id);
                        context.commit("setBridgeOtp", json_data.token_otp);

                        context.commit("setRequestUrl", "ws");
                        context.commit("createRfbOptions", true);

                        context.commit("setConnected", true);

                    } else if (json_data.status == "ERROR") {

                        context.commit("setBridgeAddress", "");
                        context.commit("setBridgeSession", "");
                        context.commit("setBridgeOtp", "");

                        context.commit("setRequestUrl", "");
                        context.commit("createRfbOptions", false);

                        context.commit("setConnected", false);

                        error_msg = "DEVICE NOT READY"
                    }
                }
            }


            context.commit("setError", error_msg )
        },
    }

});


export default store;